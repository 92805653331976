import { Link, useLocation } from "react-router-dom";
import { HOME_PATH, HOME_SEARCH_PATH } from "../utility/path";

const NoDataFound = ({ loading, selectedCategory }) => {
  const location = useLocation();
  // Determine the appropriate path dynamically
  const backToPath = location?.pathname === HOME_PATH ? HOME_SEARCH_PATH : HOME_PATH;

  return (
    <div>
      {loading && "Loading..."}
      {!loading && selectedCategory && <div className="not-found-container">
          <h2>Oops! No results found for the selected category.</h2>
          <p>It seems there are no results available for the selected category right now.</p>
          <Link to={backToPath} className="btn btn-primary btn-sm">
            Go Back to Categories
          </Link>
        </div>
      }
    </div>
  );
};

export default NoDataFound;
