import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { getUsers } from "../../services/users";
import verifyMobileRes from '../../mock/verfiyMobileRes.json';
import verfiyOtpRes from '../../mock/verfiyOtpRes.json';
import setNameRes from '../../mock/setNameRes.json';
import setPasswordRes from '../../mock/setPasswordRes.json';
import verifyPasswordRes from '../../mock/verifyPasswordRes.json';
import {BASE_URL} from "../../utility/path";
import axios from "axios";
import mime from "mime"; 
import { spinner } from "./spinnerSlice";
import { encryptPassword, getConfig, handleErrorResponse } from "../../services/functions";
import { updateAuthDataAction } from "./authSlice";

//verify  action
export const getProfileAction = createAsyncThunk(
    "profile/get",
    async (user, { rejectWithValue, getState, dispatch }) => {
      dispatch(spinner(true));
      const config = getConfig();

      try {

        const { data } = await axios.get(
          `${BASE_URL}/api/users/profile?id=${user.id}`,          
          config
        );

        dispatch(updateAuthDataAction({subscribed:data?.subscribed,toggleProvider:data?.toggleProvider}))
        //console.log('response',data);
        dispatch(spinner(false));
        
        return data;
      } catch (error) {
        dispatch(spinner(false));
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }
    }
);  

//verify  action
export const updateProfileAction = createAsyncThunk(
    "profile/update",
    async (user, { rejectWithValue, getState, dispatch }) => {
      dispatch(spinner(true));
      
      const config = getConfig();

      try {
        let formData = new FormData();
        formData.append("image",user.image);
        formData.append("id",user.id);
        formData.append("name",user.name);
        formData.append("address",user.address);
        formData.append("pincode",user.pincode);
        formData.append("fee",user.fee);
        formData.append("category",user.category);
        formData.append("isResetPassword",user.isResetPassword);
        formData.append("newPassword",user.newPassword);
        formData.append("oldPassword",user.oldPassword);
        formData.append("toggleProviderid",user.toggleProvider);
       
        const { data } = await axios.post(
          `${BASE_URL}/api/users/updateProfile`,
          formData,
          config
        );

        //console.log('response',data);
        dispatch(spinner(false));
        return data;
      } catch (error) {
        console.log(error)
        dispatch(spinner(false));
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }
    }
);

export const updateProfileAddressAction = createAsyncThunk(
  "profile/updateAddress",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig();

    try {
      let formData = new FormData();
      formData.append("id",user.id);
      formData.append("address",user.address.trim());
      formData.append("pincode",user.pincode.trim());
     
      const { data } = await axios.post(
        `${BASE_URL}/api/users/updateProfileAddress`,
        formData,
        config
      );

      dispatch(spinner(false));
      return data;
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }
  }
); 

export const updateProfilePasswordAction = createAsyncThunk(
  "profile/updatePassword",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    console.log('im here')
    
    const config = getConfig();


    try {
      let formData = new FormData();
      formData.append("id",user.id);
      formData.append("oldPassword",encryptPassword(user.oldPassword));
      formData.append("newPassword",encryptPassword(user.newPassword));
     
      const { data } = await axios.post(
        `${BASE_URL}/api/users/updateProfilePassword`,
        formData,
        config
      );

      //console.log('response',data);
      dispatch(spinner(false));
      return data;
    } catch (error) {
      console.log(error)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);

    }
  }
); 

export const updateProfileImageAction = createAsyncThunk(
  "profile/updateImage",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig("multipart/form-data");

    try {
      let formData = new FormData();
      formData.append("id",user.id);
      formData.append("image",user.image);
     
      const { data } = await axios.post(
        `${BASE_URL}/api/users/updateProfileImage`,
        formData,
        config
      );

      //console.log('response',data);
      dispatch(updateAuthDataAction({profilePhoto:data.profilePhoto}))
      dispatch(spinner(false));
      return data;
    } catch (error) {
      console.log(error)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }
  }
); 

export const updateProfileCategoryAction = createAsyncThunk(
  "profile/updateCategory",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig();

    try {
      let formData = new FormData();
      formData.append("id",user.id);
      formData.append("fee",user.fee);
      formData.append("category",user.category);
     
      const { data } = await axios.post(
        `${BASE_URL}/api/users/updateProfileCategory`,
        formData,
        config
      );

      //console.log('response',data);
      dispatch(spinner(false));
      return data;
    } catch (error) {
      console.log(error)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }
  }
); 


export const refreshProfileAction = createAsyncThunk(
  "profile/refresh",
  async (data, { rejectWithValue, getState, dispatch }) => {
    
    dispatch(spinner(false));
    return data;
  }
);  


//slices
const profilesSlices = createSlice({
  name: "profile",
  initialState: {
    data: {},
  },
  extraReducers: builder => {
    
    //verify
    builder.addCase(getProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getProfileAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(getProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //verify
    builder.addCase(updateProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //verify
    builder.addCase(updateProfileAddressAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileAddressAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileAddressAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

     //verify
     builder.addCase(updateProfilePasswordAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfilePasswordAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfilePasswordAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //
    builder.addCase(updateProfileImageAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileImageAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileImageAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

     //
     builder.addCase(updateProfileCategoryAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileCategoryAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateProfileCategoryAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });


    //refresh
    builder.addCase(refreshProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(refreshProfileAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(refreshProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });


  },
});
  
export default profilesSlices.reducer;
