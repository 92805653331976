import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  Link,
  useNavigate,
  useLoaderData
} from "react-router-dom";

//import { getUsers } from "../services/users";
import { useEffect, useState } from "react";
import {searchAction, usrAction} from "../redux/slices/usrSlice";
import CustomInput from "../components/CustomInput";
import CustomSearch from "../components/CustomSearch";
import { getGeoLocation } from "../services/functions";
import { locationSaveAction } from "../redux/slices/locationSlice";

import "../splash.css";
import { listCategoryAction } from "../redux/slices/categoriesSlice";
import logo from '../logo.png';
import { splash } from "../redux/slices/splashSlice";

function Splash() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    let ignore = false;
    if (!ignore) {

      // dispatch(splash(true));
      dispatch(locationSaveAction({}));
//      dispatch(usrAction({}));
      dispatch(listCategoryAction({}));

      setTimeout(() => {
        dispatch(splash(false));
      }, 3000);
    }

    return () => { ignore = true }
  }, []);

  return (
    <>
     <div className="splash-screen">
      <h1>Gaufi</h1>
       <div className="loading-dot">Find skilled professionals near you!!</div>
      </div>
    </>
  );
}

export default Splash;
