import { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { getConstant, handleHeader, generateRandomPassword } from "../services/functions";
import { useDispatch } from "react-redux";
import H from "../components/H";
import { setNameAction, setPasswordAction } from "../redux/slices/authSlice";

const EnterSetPassword = ({nextStep, previousStep, handleChange, state, vendor }) =>{
  const Continue = e => {
    e.preventDefault();
    //nextStep();
    dispatch(setPasswordAction({...state,locationPathname:location?.pathname,general:true}));
  }

  
  const [showTransition,setShowTransition] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    setShowTransition(true);
    handleHeader({...getConstant("SET_PASSWORD")},dispatch);
    return ()=>{setShowTransition(false)}
  },[]);

  return (
    <div className={`show-transition ${showTransition ? "show" : ""}`}>
      {/* <h3>Set Password</h3> */}
      <H value={"Set new password"}/>
      <CustomInput title="" id="passwordId" type="password" placeholder="Password" handleChange={handleChange} name="password" value={state?.password} isDisable={vendor}/>
      <CustomInput title="" id="confirmPasswordId" type="password" placeholder="Confirm password" handleChange={handleChange} name="confirmPassword" value={state?.confirmPassword} isDisable={vendor}/>

      <button className="btn btn-primary m-0" onClick={Continue} disabled={!state.confirmPassword?.trim() || !state.password?.trim() || (state.confirmPassword != state.password)}>Submit</button>
      <button className="btn btn-primary m-2" onClick={previousStep}>Back</button>
    </div>
  );
  }
  
  export default EnterSetPassword;
  
