import React, { useEffect } from "react";
import App from "./App";
import Alert from "./components/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "./redux/slices/alertSlice";

function Main() {
    
    const dispatch = useDispatch();
    const alert = useSelector(state => state?.alert);

    useEffect(()=>{
        if(alert?.show){
            setTimeout(()=>{
                dispatch(hideAlert({}));
            },2000)
        }

    },[alert])
    
    const handleClose = (e) => {
       dispatch(hideAlert({}));
    };

    const handlePayment = (e) => {
        e.preventDefault()
        // Redirect the user to the payment URL
        //window.location.href = "https://test.instamojo.com/@kapoorsahil043/9daaf37629314a09ad7b2d6a7e000c35";
      };
    
  return (
    <>
        {alert?.data?.success === "success" || alert?.data?.success === true && <Alert type={"success"} isShowInit={alert?.show} handleClose={handleClose}>
                <>
                    {alert?.data?.message && (<p>{alert?.data?.message}</p>)}
                    {!alert?.data?.message && (<p>Success!!</p>)}
                </>
        </Alert>}
        {alert?.data?.success === "error" || alert?.data?.success === false && <Alert type={"error"} isShowInit={alert?.show} handleClose={handleClose}>
                <>
                    {alert?.data?.message && (<p>{alert?.data?.message}</p>)}
                    {!alert?.data?.message && (<p>Error!!</p>)}
                </>
        </Alert>}
        {alert?.data?.success === "warning" && <Alert type={"warning"} isShowInit={alert?.show} handleClose={handleClose}>
                <>
                    {alert?.data?.message && (<p>{alert?.data?.message}</p>)}
                    {!alert?.data?.message && (<p>Warning!!</p>)}
                </>
        </Alert>}
        
        <App/>
    </>
    
  );
} 

export default Main;
