import React, { useState, useEffect } from 'react';

const DynamicPlaceholder = ({data, handleClick, className, autoComplete, handleChange, value, id}) => {
  const [currentText, setCurrentText] = useState('Search for a ');
  const [charIndex, setCharIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryNames, setCategoryNames] = useState(() =>
    data?.filter((el) => el?.name !== 'user') // Exclude entries with name 'user'
      .map((el) => `${el?.name || "electrician"}`) // Create the desired string
  );
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (charIndex < categoryNames[currentIndex]?.length && !isDeleting) {
        setCurrentText((prev) => prev + categoryNames[currentIndex][charIndex]);
        setCharIndex(charIndex + 1);
      } else if (charIndex === categoryNames[currentIndex]?.length && !isDeleting) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && charIndex > 0) {
        setCurrentText((prev) => prev.slice(0, -1));
        setCharIndex(charIndex - 1);
      } else if (isDeleting && charIndex === 0) {
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % categoryNames?.length);
      }
    }, isDeleting ? 50 : 100);

    return () => clearTimeout(timeout);
  }, [charIndex, isDeleting, currentIndex]);

  return (
    <input
      id={id}
      className={className}
      type="text"
      placeholder={currentText}
      autoComplete={autoComplete}
      onChange={handleChange || (()=>{})}
      value={value || ""}
      onClick={handleClick}
    />
  );
};

export default DynamicPlaceholder;
