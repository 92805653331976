import { useSelector } from 'react-redux';
import loupe from '../loupe.png';
import { SUBSCRIPTION_PATH } from '../utility/path';
import { useNavigate } from 'react-router-dom';

const CustomLogo = ({ users }) => {
  const navigate = useNavigate();
  const authData = useSelector(state => state?.auth?.data);

  return (
    <>
      {authData?.isLoggedIn && authData?.role === 'producer' && !authData?.subscribed && 
      <div className="card caution p-1 m-1"><div className="heading__link" onClick={(e) => { e.preventDefault(); navigate(SUBSCRIPTION_PATH) }}>
      <small><i className="fa fa-exclamation-circle fa-lg" aria-hidden="true"></i>&nbsp;<strong>Reminder:</strong>Your account is currently inactive.<br/>Subscribe to activate your account. Click Here!!</small></div></div>
      }

      <h1>
        Gaufi
        {/* <span className="overlap-container">
          <img src={loupe} className="loupe-image" alt="Loupe" />
          <span className="overlap-i">i</span>
        </span> */}
      </h1>
    </>
  );
}

export default CustomLogo;

