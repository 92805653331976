import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utility/path";
import { updateDetailsAction } from "./detailsSlice";
import { spinner } from "./spinnerSlice";
import { getConfig, handleErrorResponse } from "../../services/functions";

// action list
export const usrAction = createAsyncThunk(
    "usr/list",
    async (user, { rejectWithValue, getState, dispatch }) => {
      
    const config = getConfig();
      
    const location = localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location"))
      : {latitude:-1,longitude:-1};

  const limit = user?.limit || null;
  const skip = user?.skip || null;

      try {
        const { data } = await axios.post(
          `${BASE_URL}/api/users/list`,
          {...location,limit,skip},
          config
        );
        
        return data;
      } catch (error) {
        const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
      }
    }
);

export const usersAdminAction = createAsyncThunk(
  "user/lista",
  async (user, { rejectWithValue, getState, dispatch }) => {
    
  const config = getConfig();
    
  const location = localStorage.getItem("location")
    ? JSON.parse(localStorage.getItem("location"))
    : {latitude:-1,longitude:-1};

const limit = user?.limit || null;
const skip = user?.skip || null;

    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/users/lista`,
        {...location,limit,skip},
        config
      );
      
      return data;
    } catch (error) {
      const errorResponse = handleErrorResponse(error, dispatch);
      return rejectWithValue(errorResponse);
    }
  }
);

// action list
export const resetUserListAction = createAsyncThunk(
  "usr/reset",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      return [];
    } catch (error) {
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
  }
);

// action list
export const followAction = createAsyncThunk(
  "usr/follow",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig();

    try {
      const { data } = await axios.put(
        `${BASE_URL}/api/users/follow`,
        user,
        config
      );

      dispatch(updateDetailsAction(data));
      
      return data;
    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
  }
);

export const unfollowAction = createAsyncThunk(
  "usr/unfollow",
  async (user, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));

    const config = getConfig();

    try {
      const { data } = await axios.put(
        `${BASE_URL}/api/users/unfollow`,
        user,
        config
      );

      dispatch(updateDetailsAction(data));
      return data;
      
    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
  }
);

// search
export const searchAction = createAsyncThunk(
  "usr/search",
  async (req, { rejectWithValue, getState, dispatch }) => {
    dispatch(spinner(true));
    
    const config = getConfig();

    const location = getState()?.location?.data;
    const categoryId = req?.selectedCategory;
    const limit = req?.limit || null;
    const skip = req?.skip || null;
    
    try {

      let _URL = `${BASE_URL}/api/users/list`;
      if(categoryId){
        _URL = `${BASE_URL}/api/users/search`;
      }

      const { data } = await axios.post(
        _URL,
        {...location,category:categoryId, skip,limit},
        config
      );

      dispatch(spinner(false));

      return data;
    } catch (error) {
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
        return rejectWithValue(errorResponse);
    }
  }
);



//slices
const usrSlices = createSlice({
  name: "usr",
  initialState: {
    data: [],
  },
  extraReducers: builder => {
    //list usr
    builder.addCase(resetUserListAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(resetUserListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(resetUserListAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

     //list usr
    builder.addCase(usrAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(usrAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(usrAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //follow usr
    builder.addCase(followAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(followAction.fulfilled, (state, action) => {
      state.loading = false;
      //state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(followAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //unfollow usr
    builder.addCase(unfollowAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(unfollowAction.fulfilled, (state, action) => {
      state.loading = false;
      //state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(unfollowAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //search
    builder.addCase(searchAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(searchAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(searchAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //list user admin
    builder.addCase(usersAdminAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(usersAdminAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(usersAdminAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

  },
});
  
export default usrSlices.reducer;
