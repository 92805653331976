import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { displayDateTime, getConfig, getConstant, handleErrorResponse, handleHeader, inputTypeNumber, validateMobile } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import { setHeader } from "../redux/slices/headerSlice";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";
import CustomIcon from "../components/CustomIcon";

const ContactUs = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth.data);

  const [state, setState] = useState({});

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [dataList, setDataList] = useState([]);

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      dispatch(spinner(true));
      const config = getConfig();
      let formData = new FormData();
      formData.append("user", authData?.id);
      formData.append("name", state.name || authData.name || '');
      formData.append("mobile", state.mobile);
      formData.append("message", state.message);
      
      const { data } = await axios.post(`${BASE_URL}/api/contactus`, formData, config);
      dispatch(spinner(false));
      //navigate(CONTACT_US_PATH);
      dispatch(showAlert({ message: data.message, success: true }));
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  }

  const handleChange = input => e => {
    e.preventDefault();
    if(input == 'mobile') {
      const truncatedValue = inputTypeNumber(e.target.value,10);
      setState({ ...state, [input]: truncatedValue, [input+"Error"]: !validateMobile(truncatedValue) ?  `Enter valid ${input}.` : "" });
    }else if(input == 'message') {
      const value = e.target.value.replace(/ {2,}/g, " ");
      // Only allow letters and spaces
      const validValue = value.replace(/[^A-Za-z0-9\s]/g, "");
      setState((prevState) => ({ ...prevState, [input]: validValue, [input+"Error"]: !validValue.trim() || validValue?.trim()?.length < 5 ? `Enter valid ${input}.` : "" })); 

    } else{
      setState({ ...state, [input]: e.target.value, [input+"Error"]: !e?.target?.value || e?.target?.value?.trim()?.length < 3 ?  `Enter valid ${input}.` : "" });
    }
  }

  useEffect(() => {
    handleHeader({...getConstant("CONTACT_US")},dispatch);
    if(authData?.isLoggedIn){
      setDataList([]);
      loadData();
    }
    return () => { }
  }, []);

  const loadData = async () => {
    console.log('load')
    try {
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/contactus/list`,
        {limit,skip,user:authData?.id},
        config
      );


      if(resp?.data?.data?.length > 0){
        setDataList((prevList) => [...prevList, ...resp?.data?.data]); // Append new data
      }else{
        setLimit((prevSkip) => 0); // Update skip based on fetched data length
      }
      setSkip((prevSkip) => prevSkip + resp?.data?.data?.length || 0); // Update skip based on fetched data length

    } catch (error) {
      console.error("Error loading data:", errorResponse);
    }
  };

  return (
    <>
      <NavBar />
      <MainContainer>
      <h3>Contact Us</h3>
        {authData?.id ? 
        <>
          <CustomInput title="Name" id="nameId" type="text" placeholder="Name" handleChange={handleChange} name="name" value={authData?.name} isDisable={true} addCssToInput={"capitalize"} state={state}/>
          <CustomInput title="Message" id="messageId" type="text" placeholder="Type your message" handleChange={handleChange} name="message" value={state?.message} maxLength={100} state={state}/>
          <button className="btn btn-primary m-2" onClick={(e) => handleClick(e)} disabled={(!state?.message || !state?.message?.length > 200 || state?.messageError || state?.nameError)} >Submit</button>
        </>
         :
         <>
          <CustomInput title="Name" id="nameId" type="text" placeholder="Enter name" handleChange={handleChange} name="name" value={state?.name} addCssToInput={"capitalize"} state={state}/>
          <CustomInput title="Mobile" id="mobileId" type="number" placeholder="Enter mobile" handleChange={handleChange} name="mobile" value={state?.mobile} state={state}/>
          <CustomInput title="Message" id="messageId" type="text" placeholder="Type your message" handleChange={handleChange} name="message" value={state?.message} maxLength={100} state={state}/>
          <button className="btn btn-primary m-2" onClick={(e) => handleClick(e)} disabled={(!state?.name|| !state?.message || state?.message?.length > 200 || state?.messageError || state?.nameError)} >Submit</button>
         </> 
         }
        {authData?.isLoggedIn && <div>
          {dataList?.length > 0 && <h5 className="mt-2">Your message list</h5>}
          {
            dataList?.map((el, index) => (
              <div key={index} className="card mb-1">
                <div className="card-header">Date: {displayDateTime(el?.createdAt)}</div>
                <div className="card-body">
                  <div><strong>Message: </strong>{(el?.message)}</div>
                  {el?.reply && <div className="text-success">
                      <CustomIcon type="reply"/><i className="bi bi-reply p-2">{el?.reply}</i>
                    </div>}
                </div>
              </div>
            ))}
          {/* <button className="btn btn-outline-primary btn-sm m-2" onClick={loadData} disabled={limit < 1}>view more</button> */}
        </div>}
      </MainContainer>
    </>
  );
}

export default ContactUs;
