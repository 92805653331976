import { useEffect, useState } from "react";
import CircleWithAlphabet from "./CircleWithAlphabet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { USER_MANAGEMENT_PATH } from "../utility/path";

const CustomImage = ({state, className, size}) => {
  
  const [defaultSrc] = useState('');
  const [newSrc, setNewSrc] = useState();
  const [initialLetter,setInitialLetter] = useState(state?.name?.charAt(0));
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // Track hover/press state
  const authData = useSelector(state => state?.auth?.data);
  const urllocation = useLocation();
  
  const isUserManagementPath = urllocation?.pathname === USER_MANAGEMENT_PATH;

  useEffect(() => {
    setNewSrc(state?.image);
    setInitialLetter(state?.name?.charAt(0));
    
  }, [state?.image]);

  const isAdmin = () => {return authData?.isAdmin && isUserManagementPath};
  const handleMouseEnter = () => {
    if(!isAdmin()){return;}
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if(!isAdmin()){return;}
    setIsHovered(false);
  };

  const handleTouchStart = () => {
    if(!isAdmin()){return;}
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    if(!isAdmin()){return;}
    setIsHovered(false);
  };

  const imageStyles = {
    transition: "transform 0.3s ease-in-out", // Smooth transition
    transform: isHovered ? "scale(4.5)" : "scale(1)", // Scale up on hover/press
    width: size,
    height: size,
    objectFit: "cover",
    borderRadius: "50%",
  };

  return (
    <>
      {
        !imageError ? (
          <img
            src={newSrc}
            className={className}
            style={imageStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onError={(e) => {
              //e.target.src = defaultSrc;
              //console.log("err in image")
              setImageError(true);
            }}
          />) : ''}
      {imageError ? (<CircleWithAlphabet letter={initialLetter || "Hi!!"} size={size} color={"linear-gradient(to bottom,#dee2e6,#495057)"} />) : ''}
    </>
  );
}

export default CustomImage;
