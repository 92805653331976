import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/Navigation/NavBar";
import CustomInput from "../components/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, PROFILE_PATH } from "../utility/path";
import { encryptPassword, getConfig, getConstant, handleErrorResponse, handleHeader } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import axios from "axios";
//import { setHeader } from "../redux/slices/headerSlice";
import MainContainer from "../components/MainContainer";
import { showAlert } from "../redux/slices/alertSlice";

const ChangePassword = ({ }) => {

  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    id: data?.id
  });

  const handleChange = input => e => {
    setState({ ...state, [input]: e.target.value });
  }

  const handleClick = async (event) => {
    event.preventDefault();
    dispatch(spinner(true));
    const config = getConfig();

    try {
      let formData = new FormData();
      formData.append("id", state.id);
      formData.append("oldPassword", encryptPassword(state.oldPassword));
      formData.append("newPassword", encryptPassword(state.newPassword));
      const { data } = await axios.post(`${BASE_URL}/api/users/updateProfilePassword`, formData, config);
      //dispatch(setHeader({ gotoName: "Home", gotoAction: HOME_PATH }));
      dispatch(spinner(false));
      dispatch(showAlert({ message: 'Success', success: true }));
      navigate(PROFILE_PATH);
      return data;
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return errorResponse;
    }
  }

  const renderForm = () => {
    return (
      <>
        <CustomInput title="" id="oldPasswordId1" type="password" placeholder="Enter old password" handleChange={(e) => handleChange(e)} name="oldPassword" value={state.oldPassword || ''}/>
        <CustomInput title="" id="newPasswordId1" type="password" placeholder="Enter new password" handleChange={(e) => handleChange(e)} name="newPassword" value={state.newPassword || ''}/>
        <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} disabled={profile?.loading || !state.oldPassword?.trim() || !state.newPassword?.trim()}>{profile?.loading ? "Loading.." : "Save"}</button>
      </>
    );
  }

  useEffect(() => {
    handleHeader({...getConstant("CHANGE_PASSWORD")},dispatch);

    return () => {}
  }, []);

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>
    </>
  );
}

export default ChangePassword;
