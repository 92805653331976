import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/Navigation/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, HOME_PATH, PROFILE_PATH, SUBSCRIPTION_PATH } from "../utility/path";
import { getConfig, getConstant, handleErrorResponse, handleHeader } from "../services/functions";
import { spinner } from "../redux/slices/spinnerSlice";
import axios from "axios";
import { updateAuthDataAction } from "../redux/slices/authSlice";
import UploadImage from "../components/UploadImage";
import { setHeader } from "../redux/slices/headerSlice";
import MainContainer from "../components/MainContainer";
import { showAlert } from "../redux/slices/alertSlice";
import imageCompression from 'browser-image-compression';

const ChangeImage = ({ }) => {

  const dispatch = useDispatch();
  const authData = useSelector(state => state?.auth?.data);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(undefined);

  const [state, setState] = useState({
    image: authData?.profilePhoto,
    id: authData?.id
  });

  const handleChangePhoto = input => async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    console.log(file);

    const validTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxFileSize = 5 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      console.error("Invalid file type. Only JPEG, PNG, and WebP are allowed.");
      return;
    }

    if (file.size > maxFileSize) {
      console.error("File size exceeds the maximum limit of 5MB.");
      return;
    }

    try {
      setSelectedFile(URL.createObjectURL(file));

      // Compression options
      const options = {
        maxSizeMB: 1, // Set max size to 1MB
        maxWidthOrHeight: 1024, // Resize to a maximum of 1024px width or height
        useWebWorker: true, // Use Web Workers for faster compression
      };

       // Compress image
       const compressedFile = await imageCompression(file, options);
       setState({ ...state, [input]: compressedFile });

    } catch (error) {
      console.error('Error while compressing image:', error);
    }

    
  }

  const handleClick = async (event) => {
    event.preventDefault();
    dispatch(spinner(true));
    const config = getConfig("multipart/form-data");

    try {
      let formData = new FormData();
      formData.append("id", state.id);
      formData.append("image", state.image);
      const { data } = await axios.post(`${BASE_URL}/api/users/updateProfileImage`, formData, config);
      dispatch(updateAuthDataAction({ profilePhoto: data.profilePhoto }))
      dispatch(setHeader({ gotoName: "Home", gotoAction: HOME_PATH }));
      dispatch(spinner(false));
      dispatch(showAlert({ message: 'Success', success: true }));
      if(!authData?.subscribed && authData?.role === "producer"){
        navigate(SUBSCRIPTION_PATH);
      } else {
        const detailsPage = localStorage.getItem("redirect");
        if(detailsPage){
          localStorage.removeItem("redirect");
          navigate("/details/"+detailsPage);
        }else{
          navigate(PROFILE_PATH);
        }
      }
      
      return data;
    } catch (error) {
      console.log(error?.message)
      dispatch(spinner(false));
      const errorResponse = handleErrorResponse(error, dispatch);
      return errorResponse;
    }
  }

  const renderForm = () => {
    return (
      <>
        <UploadImage selectedFile={selectedFile} handleChange={handleChangePhoto} />
        <button type="button" className="btn btn-primary" onClick={(e) => handleClick(e)} disabled={authData?.loading || !selectedFile}>{authData?.loading ? "Loading.." : "Save"}</button>
      </>
    );
  }

  useEffect(() => {
    handleHeader({...getConstant("CHANGE_PHOTO")},dispatch);

    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(selectedFile);
      }
    }
  }, []);

  return (
    <>
      <NavBar />
      <MainContainer>
        {renderForm()}
      </MainContainer>
    </>
  );
}

export default ChangeImage;
