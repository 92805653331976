import { useCallback, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useLoaderData
} from "react-router-dom";

import EnterMobile from './EnterMobile';
import EnterOtp from './EnterOtp';
import EnterName from './EnterName';
import EnterSetPassword from './EnterSetPassword';
import EnterPassword from './EnterPassword';
import { useDispatch, useSelector } from "react-redux";
import { logoutAction, previousAction, setNameAction, setPasswordAction, verifyMobileAction, verifyOtpAction, verifyPasswordAction } from "../redux/slices/authSlice";
import NavBar from "../components/Navigation/NavBar";
import { refreshProfileAction } from "../redux/slices/profilesSlice";
import { HOME_PATH, LOGOUT_PATH, REGISTER_GENERAL_PATH, CHANGE_IMAGE_PATH, SUBSCRIPTION_PATH } from "../utility/path";
import { generateRandomPassword, isDigit, validatePincode_v2 } from "../services/functions";
import { REGISTER_PATH } from "../utility/path";
import { spinner } from "../redux/slices/spinnerSlice";

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth?.data);
  const categoriesData = useSelector((state) => state?.categories?.data);
  const loaderData = useLoaderData();
  const _r = generateRandomPassword();

  const [state, setState] = useState({
    step: 1,
    mobile: authData?.mobile || "",
    otp: "",
    name: "",
    password: "",
    confirmPassword: "",
    address: "",
    pincode: "",
    category: categoriesData?.[0]?.id,
    fee: 1,
    locationPathname: "",
    errorpincode: "",
    referral:" ",
    isUserCategory:true,
    pincodeError:""
  });

  const [currentStep, setCurrentStep] = useState([1]);
  const [next, setNext] = useState(1);

  const previousStep = () => {
    dispatch(previousAction(currentStep[currentStep.length - 1]));
    setCurrentStep((prev) =>
      prev.filter((el) => el !== currentStep[currentStep.length - 1])
    );
  };

  const handleChange = useCallback((input) => (e) => {
    e.preventDefault();
    const value = e.target.value;

    if (input === "pincode") {
      const numericValue = value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 6);
      setState((prevState) => ({ ...prevState, [input]: truncatedValue, [input+"Error"]: `Enter valid ${input}.` })); 

      if (truncatedValue?.length === 6) {
        validatePincode_v2(truncatedValue,setState);
      }
    } else if (input === "mobile") {
      const numericValue = value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);
      setState((prevState) => ({ ...prevState, [input]: truncatedValue }));
    } else if (input === "fee") {
      const numericValue = value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 5);
      if(loaderData?.vendor){
        setState((prevState) => ({ ...prevState, [input]: truncatedValue, password: _r, confirmPassword:_r }));
      }else{
        setState((prevState) => ({ ...prevState, [input]: truncatedValue }));
      }
    } else if (input === "category") {
      const isUserCategory = categoriesData?.some(
        (c) => c.name === "user" && value === c.id
      );
      setState((prevState) => ({
        ...prevState,
        [input]: value,
        isUserCategory,
      }));
    } else if (input === "name" || input === "address") {
        // Replace multiple spaces with a single space
        const value = e.target.value.replace(/ {2,}/g, " ");
        // Only allow letters and spaces
        const validValue = value.replace(/[^A-Za-z0-9\s]/g, "");

        setState((prevState) => ({ ...prevState, [input]: validValue, [input+"Error"]: !validValue.trim() || validValue.trim()?.length < 3 ? `Enter valid ${input}.` : "" })); 
        
    } else {
      setState((prevState) => ({ ...prevState, [input]: value }));
    }
  }, [categoriesData]);

  const handleChangeOtp = (input, value) => {
    if(!isDigit(value)){
      return;
    }
    setState((prevState) => ({ ...prevState, [input]: value }));
  };

const nextStep = () => {}

  const renderForm = () => {
    switch (next) {
      case 1:
        return <EnterMobile nextStep={nextStep} handleChange={handleChange} state={state} vendor={loaderData?.vendor}/>;
      case 2:
        return <EnterPassword nextStep={nextStep} previousStep={previousStep} handleChange={handleChange} state={state} />;
      case 3:
        return <EnterOtp nextStep={nextStep} previousStep={previousStep} handleChangeOtp={handleChangeOtp} state={state} vendor={loaderData?.vendor}/>;
      case 4:
        return <EnterName nextStep={nextStep} previousStep={previousStep} handleChange={handleChange} state={state} />;
      case 5:
        return <EnterSetPassword nextStep={nextStep} previousStep={previousStep} handleChange={handleChange} state={state} vendor={loaderData?.vendor}/>;
      case 6:
        //navigate(HOME_PATH);
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    setNext(1);
    return () => {
      setNext(1);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname === LOGOUT_PATH) {
      dispatch(refreshProfileAction({}));
      dispatch(logoutAction({}));
      goto();
    } else if (authData?.isLoggedIn && !loaderData?.vendor && !authData?.profilePhoto) {
      navigate(CHANGE_IMAGE_PATH);
    } else if (authData?.isLoggedIn && !loaderData?.vendor && !authData?.subscribed && authData?.role === "producer") {
      navigate(SUBSCRIPTION_PATH);
    } else if (authData?.isLoggedIn && !loaderData?.vendor) {
      const detailsPage = localStorage.getItem("redirect");
      if(detailsPage){
        localStorage.removeItem("redirect");
        navigate("/details/"+detailsPage);
      }else{
        navigate(HOME_PATH);
      }
    } else if (authData?.isLoggedIn && loaderData?.vendor) {
      dispatch(logoutAction({}));
      navigate(REGISTER_GENERAL_PATH);
    }

    setNext(authData?.nextStep || 1);
  }, [authData?.nextStep, location?.pathname]);

  const goto = () => {
    dispatch(refreshProfileAction({}));
    return navigate(REGISTER_PATH);
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="row">
          <div className="col-md-6 offset-md-3">{renderForm()}</div>
        </div>
      </div>
    </>
  );
};

export default Register;