import { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../components/Card";
import DetailsModel from "./DetailsModel";
import { detailsAction } from "../redux/slices/detailsSlice";

const Cards = ({ users }) => {
  let idx = 1;
  const dispatch = useDispatch();

  const openDetailsModal = async (input) => {
    if (!input) {
      console.log('return')
      return;
    }

    // get details data
    dispatch(detailsAction({ otherUserId: input }));

    // show modal
    const modalElement = document.getElementById('detailsModal');
    if (modalElement) {
      const modalElementHeader = document.getElementById('header');
      modalElementHeader?.classList?.add('hidden');
      $(modalElement).modal('show'); // For Bootstrap 4
      modalElement.focus();
    }
  }

  return (
    <div className="cards_ct">
      {
        users.map((user) => (
          <Card idx={idx++} key={user.id} user={user} openDetailsModal={openDetailsModal} />
        ))}
      <DetailsModel></DetailsModel>
    </div>
  );
}

export default Cards;
