import CustomTooltip from "./CustomTooltip";

const CustomSelect = ({ title,handleChange,name,data,state, defaultOptionValue, isDisable,tooltipInfo }) => {

    return (
        <>
            <h5>{title}
             {tooltipInfo ? (<CustomTooltip tooltipInfo={tooltipInfo}/>) : ''}
            </h5>
            {true ? (<div className="form-floating mb-3">
              <select className="form-select form-select-md capitalize bg-light" aria-label=".form-select-md example" onChange={handleChange(name)}
              value={state?.category?.toString()} disabled={isDisable}>
                {/* <option defaultValue>{defaultOptionValue}</option> */}
                <option>{defaultOptionValue}</option>
                {data.map((el)=>(
                  <option key={el.id} value={el.id}>{el.name}</option>
                ))}
              </select>
            </div>):
            ('')
            }
        </>
    );
}

export default CustomSelect;
