import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import { logoutAction, verifyPasswordAction } from "../redux/slices/authSlice";
import { useEffect, useState } from "react";
import { getConstant, handleHeader } from "../services/functions";
import H from "../components/H";
import { FORGOT_PWD_PATH } from "../utility/path";

const EnterPassword = ({nextStep, previousStep, handleChange, state}) =>{
  const dispatch =  useDispatch();
  const Continue = e => {
    e.preventDefault();
    //nextStep();
    dispatch(verifyPasswordAction({...state,locationPathname:location?.pathname,deviceId:localStorage.getItem("deviceId")}));
  }
  
  const [showTransition,setShowTransition] = useState(false);

  useEffect(()=> {
    setShowTransition(true);
    handleHeader({...getConstant("VERIFY_PASSWORD")},dispatch);

    return ()=>{setShowTransition(false)}
  },[])

  return (
    <div className={`show-transition ${showTransition ? "show" : ""}`}>
      <H value={"Verify Password"}/>
      <CustomInput title="" id="passwordId" type="password" placeholder="Enter password" handleChange={handleChange} name="password" value={state?.password}/>

      <ul>
        <li onClick={()=>{dispatch(logoutAction({}))}}>
          <NavLink to={FORGOT_PWD_PATH}>
                  Forgot password?
          </NavLink>
        </li>
      </ul>

      <button className="btn btn-primary m-0" onClick={Continue} disabled={!state?.password}>Submit</button>
      <button className="btn btn-primary m-2" onClick={previousStep} >Back</button>
      
    </div>
  );
  }
  
  export default EnterPassword;
  
