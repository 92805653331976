import { useEffect, useState } from "react";
import CustomImage from "./CustomImage";
import { displayDateTime } from "../services/functions";
import "../reviews.css";

const CommentModel = ({ commentByOthersData }) => {

  const closeModal = async () => {
    const modalElement = document.getElementById('commentModal');
    if (modalElement) {
      $(modalElement).modal('hide');
    }
  }

  if(!commentByOthersData?.length < 0){
    return (<div className="modal fade" id="commentModal" tabIndex="-1" role="dialog" aria-labelledby="commentModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content">No reviews</div>
      </div>
    </div>);
  }

  if(commentByOthersData?.length > 0){
    return (
      <div className="modal fade" id="commentModal" tabIndex="-1" role="dialog" aria-labelledby="commentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="commentModalLabel">Reviews</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true" className="h5 text-danger"><b>&times;</b></span>
              </button>
            </div>
            <div className="modal-body">
              <ul className="reviews-list">
                {commentByOthersData?.map((el) => (
                  <li className="review-item bg-light border rounded" key={el.id}>
                    <div className="d-flex align-items-start px-2 pt-2">
                      {/* <img class="rounded-circle shadow-1-strong me-3"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(33).webp" alt="avatar" width="60"
                  height="60" /> */}
                      <CustomImage className="thumbnail circle px-2 pt-2" state={{ image: el.user[0]?.profilePhoto, name: el?.user[0]?.name }} size={"3rem"} />
                      <div className="review-content px-2">
                        <h6 className="fw-bold capitalize">{el.user[0]?.name}</h6>
                        <div className="d-flex align-items-center">
                          <p className="text-muted">
                            <i><small>{displayDateTime(el.createdAt)}</small></i>
                            {/* <span class="badge bg-danger">Rejected</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mx-3">{el.comment}</div>
                    {/* <hr className="mt-1 mb-0" /> */}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <button type="btn button" className="btn btn-sm btn-secondary" onClick={closeModal}>Close</button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommentModel;
