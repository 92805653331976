import { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { getConstant, handleHeader } from "../services/functions";
import { useDispatch } from "react-redux";
import H from "../components/H";
import { verifyMobileAction } from "../redux/slices/authSlice";

const EnterMobile = ({ nextStep, handleChange, state, vendor }) => {
  //console.log("mobile state",state)

  const [showTransition,setShowTransition] = useState(false);
  const dispatch = useDispatch();

  const Continue = e => {
    e.preventDefault();
    //nextStep();
    dispatch(verifyMobileAction({...state,locationPathname:location?.pathname, vendor:vendor}));
  }


  useEffect(()=>{
    setShowTransition(true);
    handleHeader({...getConstant("VERIFY_MOBILE")},dispatch);
    return ()=>{setShowTransition(false)}
  },[])

  return (
    <div className={`show-transition ${showTransition ? "show" : ""}`}>
      <H value={"Verify Mobile"}/>
      <CustomInput title="" id="mobileId" type="number" placeholder="Mobile number" handleChange={handleChange} name="mobile" value={state?.mobile} state={state}/>
      <button className="btn btn-primary" onClick={Continue} disabled={!state?.mobile}>Submit</button>
    </div>
  );
}

export default EnterMobile;
