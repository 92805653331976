import React, { useEffect, useState } from "react";
import NavBar from "../components/Navigation/NavBar";
import { getConfig, getConstant, handleHeader } from "../services/functions";
import { BASE_URL, CONTACT_US_PATH } from "../utility/path";
import axios from "axios";
import MainContainer from "../components/MainContainer";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
    const [state, setState] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        handleHeader({...getConstant("PRIVACY_POLICY")},dispatch);
    }, []);

    return (

        <>
            <NavBar />
            <MainContainer>
                <h3>Privacy Policy</h3>
                <div className="mb-4">
                    <div className="h5">1. Introduction</div>
                    Welcome to Gaufi ("the Platform"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our Platform. By accessing or using the Platform, you agree to the terms of this Privacy Policy.
                </div>
                <div className="mb-4">
                    <div className="h5">2. Information We Collect</div>
                    We collect various types of information to provide and improve our services:
                    <ul>
                        <li><b>Personal Information:</b> This includes information you provide when creating an account, such as your name, address, phone number, and professional details.</li>
                        <li><b>Usage Data:</b> We collect information about your interactions with the Platform, including search queries, pages visited, and other activities.</li>
                        <li><b>Device Information:</b> We collect information about the device you use to access the Platform, including IP address, browser type, and operating system.</li>
                        <li><b>Location Data:</b> We may collect and process information about your location to provide location-based services.</li>
                    </ul>
                    
                </div>
                <div className="mb-4">
                    <div className="h5">3. How We Use Your Information</div>
                    We use the information we collect for various purposes, including:
                    <ul>
                        <li><b>Providing Services:</b> To facilitate connections between Users and Professionals.</li>
                        <li><b>Improving Services:</b> To enhance the functionality and user experience of the Platform.</li>
                        <li><b>Communication:</b> To send you updates, notifications, and other information related to your use of the Platform.</li>
                        <li><b>Marketing:</b> To provide you with promotional materials and offers that may be of interest to you.</li>
                        <li><b>Compliance:</b> To comply with legal obligations and protect our legal rights.</li>
                    </ul>
                </div>
                <div className="mb-4">
                    <div className="h5">4. Sharing Your Information</div>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:

                    <ul>
                        <li><b>Service Providers:</b> We may share your information with third-party service providers who assist us in operating the Platform and providing services to you.</li>
                        <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                        <li><b>Business Transfers:</b> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</li>
                    </ul>
                    
                </div>
                <div className="mb-4">
                    <div className="h5">5. Data Security</div>
                    We implement a variety of security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    
                </div>
               
                <div className="mb-4">
                    <div className="h5">6. Your Rights</div>
                    You have the right to:
                    <ul>
                        <li><b>Access:</b> Request access to the personal information we hold about you.</li>
                        <li><b>Correction:</b> Request correction of any inaccurate or incomplete information.</li>
                        <li><b>Deletion:</b> Request deletion of your personal information, subject to certain exceptions.</li>
                        <li><b>Opt-Out:</b> Opt-out of receiving marketing communications from us.</li>
                    </ul>
                    To exercise these rights, please contact us at <NavLink to={CONTACT_US_PATH}>Contact us</NavLink>.
                </div>
                <div className="mb-4">
                    <div className="h5">7. Cookies and Tracking Technologies</div>
                    The Platform reserves the right to modify these Terms at any time. Users will be notified of any significant changes, and continued use of the Platform will constitute acceptance of the revised Terms.
                </div>
                <div className="mb-4">
                    <div className="h5">8. Children's Privacy</div>
                    Our Platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.

                </div>
                <div className="mb-4">
                    <div className="h5">9. Changes to This Privacy Policy</div>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </div>
                <div className="mb-4">
                    <div className="h5">10. Contact Information</div>
                    For any questions or concerns about these Terms, please contact us at <NavLink to={CONTACT_US_PATH}>Contact us</NavLink>.
                </div>
            </MainContainer>
        </>
    );
};

export default PrivacyPolicy;
