import { useState, useEffect } from "react";

const useOnScroll = () => {
  const [scrollObject, setScrollObject] = useState({
    pageYOffset: window.pageYOffset,
  });

  const handleScroll = () => {
    setScrollObject({
      pageYOffset: window.pageYOffset,
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup the scroll event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the event listener is added once

  return scrollObject;
};

export default useOnScroll;
