import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { displayDateTime, getConfig, getConstant, handleErrorResponse, handleHeader } from "../services/functions";
import MainContainer from "../components/MainContainer";
import { spinner } from "../redux/slices/spinnerSlice";
import { BASE_URL } from "../utility/path";
import axios from "axios";
import NavBar from "../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../redux/slices/alertSlice";
import CustomIcon from "../components/CustomIcon";

const ContactUsManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.data);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [dataList, setDataList] = useState([]);
  const [state, setState] = useState({});

  const loadData = async () => {
    console.log('load')
    try {
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/contactus/lista`,
        {limit,skip},
        config
      );

      if(resp?.data?.data?.length > 0){
        setDataList((prevList) => [...prevList, ...resp?.data?.data]); // Append new data
      }else{
        setLimit((prevSkip) => 0); // Update skip based on fetched data length
      }
      setSkip((prevSkip) => prevSkip + resp?.data?.data?.length || 0); // Update skip based on fetched data length
      
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const handleChange = useCallback((input) => (e) => {
    //console.log('change',input,e?.target?.value);
    setState({ ...state, [input]: e.target.value });
  })

  const handleClick = ((cid) => (e) => {
    console.log('click',state[cid],cid);
    if(!state[cid] || !state[cid]?.trim()?.length){
      console.log('return')
      return;
    }
    sendReply(cid,state[cid]);
    //setState({ ...state, [cid]: "" });

  });

  const sendReply = async (cid,reply) => {
    console.log('reply',cid)
    try {
      const config = getConfig();
      const resp  = await axios.post(
        `${BASE_URL}/api/contactus/reply`,
        {id:authData?.id,cid,reply},
        config
      );
      //console.log('data:',resp?.data);
      const updated = dataList.map(ct => {
        if (ct.id === cid) {
          return { ...ct, reply:resp?.data?.reply,updatedAt:resp?.data?.updatedAt,createdAt:resp?.data?.createdAt};
        }
        return ct;
      });
    setDataList(updated);

    } catch (error) {
      console.error("Error loading data:", error);
      const errorResponse = handleErrorResponse(error, dispatch);
    }
  };
  
  useEffect(() => {
    handleHeader({...getConstant("CONTACT_US")},dispatch);
    setDataList([]);
    loadData()
  },[]);


  return (
    <>
      <NavBar />
      <MainContainer>
        <h3>Contact Us Management</h3>
        {authData?.isLoggedIn && authData?.isAdmin ? (
          <>
          <div>{dataList?.length}</div>
          {
          dataList?.map((el,index)=>(
              <div key={index} className="card mb-1">
                <div className="card-header">Date:{displayDateTime(el?.createdAt)}</div>
                <div className="card-body">
                  {el?.user[0]?.name && <>
                    <strong>With Login:</strong>
                    <div><strong>Name: </strong>{el?.user[0]?.name}</div>
                    <div><strong>Mobile: </strong>{el?.user[0]?.mobile}</div>
                    </>}

                  {!el?.user[0]?.name && <>
                    <strong>Without Login</strong>
                      <div><strong>Name: </strong>{el?.name}</div>
                      <div><strong>Mobile: </strong>{el?.mobile}</div>
                    </>}

                    <div><strong>Message: </strong>{el?.message}</div>
                  {el?.reply && <div className="text-success">
                      <CustomIcon type="reply"/><i className="bi bi-reply p-2">{el?.reply}</i>
                    </div>}
                </div>
                <div className="card-footer">Reply:
                  <input type="text" onChange={handleChange(el?.id)}/>
                  <button className="btn btn-outline-primary btn-sm m-3" disabled={!state?.[el?.id]} onClick={handleClick(el?.id)}>Submit</button>
                </div>
              </div>
          ))}
          <button className="btn btn-outline-primary btn-sm m-3" onClick={loadData} disabled={limit < 1}>view more</button>
          </>
        ) : (
          <div>You are not authorized to view this page.</div>
        )}
      </MainContainer>
    </>
  );
};

export default ContactUsManagement;