import React, { useRef, useState } from 'react';
import { isDigit } from '../services/functions';

const OtpComponent = ({handleChangeOtp}) => {
    const [otp, setOtp] = useState(['', '', '', '']);

    const firstInputRef = useRef(null);
    const secondInputRef = useRef(null);
    const thirdInputRef = useRef(null);
    const fourthInputRef = useRef(null);
    // const fifthInputRef = useRef(null);
    // const sixthInputRef = useRef(null);
  
    const handleChange = (e, inputRef,index) => {
        e.preventDefault();

      if(!isDigit(e?.target?.value)){
        return;
      }
      if (e.target.value.length === 1) {
        const newOtp = [...otp];
        newOtp[index] = event.target.value;
        setOtp(newOtp);
        handleChangeOtp('otp',newOtp.join(''));

        const nextInput = inputRef?.current?.nextSibling;
        if (nextInput) {
          nextInput.value = '';
          nextInput.focus();
        }
        //e.target.value = ""; // reset input value
      }
      
    };

    const handleOtpKeyPress = (event, index) => {

        //console.log('ssdsd',event.key);
        const allowedKeys = [
          "Backspace",
          "Delete",
          "ArrowLeft",
          "ArrowRight",
          "Tab",
        ];

        if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
          event.preventDefault();
        }
        // if (index < 5 && event.key === 'Enter') {
        //   document.getElementById(`otp-input-${index + 1}`).focus();
        // }

        // if (index < 5 && event.key !== 'Backspace' && event.key !== 'Delete') {
        //     const nextIndex = index + 1;
        //     const nextInputRef = [secondInputRef, thirdInputRef, fourthInputRef, fifthInputRef, sixthInputRef][index];
        //     nextInputRef.current.focus();
        //   }
    };
  
    return (
      <>
        <div className="input-group mb-3">
            <input placeholder='-' className="form-control otpInput bg-light" type="number" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 0)} ref={firstInputRef} onChange={(e) => handleChange(e, firstInputRef,0)} />
            <input placeholder='-' className="form-control otpInput bg-light" type="number" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 1)} ref={secondInputRef} onChange={(e) => handleChange(e, secondInputRef,1)} />
            <input placeholder='-' className="form-control otpInput bg-light" type="number" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 2)} ref={thirdInputRef} onChange={(e) => handleChange(e, thirdInputRef,2)} />
            <input placeholder='-' className="form-control otpInput bg-light" type="number" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 3)} ref={fourthInputRef} onChange={(e) => handleChange(e, fourthInputRef,3)} />
            {/* <input placeholder='-' className="form-control otpInput" type="text" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 4)} ref={fifthInputRef} onChange={(e) => handleChange(e, fifthInputRef,4)} />
            <input placeholder='-' className="form-control otpInput" type="text" maxLength="1" onKeyDown={(event) => handleOtpKeyPress(event, 5)} ref={sixthInputRef} onChange={(e) => handleChange(e, sixthInputRef,5)} /> */}
        </div>
      </>
    );
  };
  
export default OtpComponent;
