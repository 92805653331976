import { useState } from "react";
import { useSelector } from "react-redux";
import "../categories.css";
import CategoryCard from "./CategoryCard";

const HomeCategories = ({ handleClick }) => {
  const categories = useSelector(state => state?.categories?.data);
  const [imageError, setImageError] = useState(false);

  return (
    <div className="categories-container col-md-6 offset-md-3">
      {categories?.map((category, index) => {
        if (category?.name === 'user') return null;
        return (<CategoryCard key={index} category={category} handleClick={handleClick} />)
      })}
    </div>
  );
}

export default HomeCategories;