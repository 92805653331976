import { useEffect, useState } from "react";
import { PAGE_CONSTANT, runAnalytic, runAnalytic_v2 } from "../services/functions";
import "../categories.css";
import { useDispatch, useSelector } from "react-redux";
import DynamicPlaceholder from "./DynamicPlaceholder";

const SearchModel = ({handleClick }) => {
  const dispatch = useDispatch();
  const authData = useSelector(state => state?.auth?.data);
  const categories = useSelector(state => state?.categories?.data);

  const [filteredCategories,setFilteredCategories] = useState([]);
  const [enteredValue,setEnteredValue] = useState("");
  const [selectedCategory,setSelectedCategory] = useState("");
  const DYNAMIC_PLACEHOLDER_INPUT_ID = "dynamicPlaceholderInputId";

  const handleChange = (e) => {
    e.preventDefault();
    //analycis
    //runAnalytic(e?.target?.value, authData,dispatch,0,null,null);
    runAnalytic_v2({categoryToAdd:e?.target?.value, authData,dispatch,page:PAGE_CONSTANT.SEARCH_PAGE})

    setEnteredValue((prev)=>{return e.target.value});
    
    const temp = categories?.filter((c)=>{
        if(c.name.toLowerCase()!='user'){ // skipping user category
            return c?.name.indexOf(e.target.value.toLowerCase()) > -1
        }
    });

    setFilteredCategories(temp);
}
const _handleClick = data => (event) =>{
  event.preventDefault();
  //runAnalytic(data?.name, authData,dispatch,0,null,data?.id);
  runAnalytic_v2({categoryToAdd:data?.name, authData,dispatch,page:PAGE_CONSTANT.SEARCH_PAGE,searchCategoryId:data?.id})
  setSelectedCategory(data?.name);
  handleCloseModal();
  handleClick(data);
}

const handleOpenModal = async () => {
  console.log('open')
  const modalElement = document.getElementById('searchModal');
  if(modalElement){
    console.log('true')
    const modalElementHeader = document.getElementById('header');
    modalElementHeader.classList.add('hidden');
  }
};

const handleCloseModal = async () => {
  console.log('close')

  $('#searchModal').modal('hide')

  const modalElementHeader = document.getElementById('header');
  modalElementHeader?.classList?.remove('hidden');
  
  // Find the modal by its ID and hide it
  const modalElements = document.getElementsByClassName('modal-backdrop');

  if (modalElements.length > 0) {
    Array.from(modalElements).forEach((modalElement) => {
      // Add logic to remove/hide the modal
      modalElement.classList.remove('modal-backdrop');
    });

    // Also handle the modal content element (e.g., modal dialog)
    const modals = document.getElementsByClassName('modal');
    Array.from(modals).forEach((modal) => {
      modal.classList.remove('show');
      modal.style.display = 'none';
    });

    // If using Bootstrap 4:
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  } else {
    console.log('No modal-backdrop found.');
  }

  

};

useEffect(() => {
  console.log('use effect - searchmodel');
  const modalElement = document.getElementById('searchModal');
  $(modalElement).on('shown.bs.modal', function () {
    console.log('shown fn');
    const inputElement = document.getElementById(DYNAMIC_PLACEHOLDER_INPUT_ID);
    if (inputElement) {
      const modalElementHeader = document.getElementById('header');
      modalElementHeader?.classList?.add('hidden');

      //setEnteredValue((prev)=> "");
      inputElement.focus(); // Focus the input after the modal is shown
    } else {
      console.error("Input element not found");
    }
  });

  //handleOpenModal();

  return () => { handleCloseModal() }
},[]);

const handleClear = async () => {
  console.log('clear');
  setEnteredValue("")
}

  return (
    <div className="modal fade" id="searchModal" role="dialog" aria-labelledby="searchModalLabel" aria-hidden="true" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row w-100 gx-0">
              <div className="col-md-6 offset-md-3">
                {/* <div className="form-group input-group">
                    <input id={"searchId"} type={"text"} className="form-control custom-search-input"
                    placeholder={"search electrician, plumber, artist etc.."}
                    onChange={handleChange("search")} 
                    value={enteredValue} 
                    autoComplete="off" />
                </div> */}
                <div className="clear_outer_back_btn" onClick={handleCloseModal}>&larr;</div>
                <DynamicPlaceholder data={categories} className={"form-control custom-search-input bg-light px-5"} autoComplete={"off"} handleChange={handleChange} value={enteredValue} handleClick={handleOpenModal} id={DYNAMIC_PLACEHOLDER_INPUT_ID}/>
                {enteredValue && <div className="clear_outer"><span className="clear_inner" onClick={handleClear}>&times;</span></div>}
              </div>
            </div>
          </div>
          <div className="modal-body categories-container row col-md-6 offset-md-3">
            {!enteredValue && (<ul className="categories-list">
              {categories.map((category, index) => {
                 // Skip rendering if category.name is "user"
                if (category?.name === 'user') return null;
             return (
                <li className="category-item my-color-bg-light-1 d-flex justify-content-center align-items-center" key={index} onClick={_handleClick(category)}>
                  <div className="category-name">{category.name}</div>
                </li>
              )})}
            </ul>)}
            
            {enteredValue && (<ul className="categories-list">
              {filteredCategories.map((category, index) => {
                 // Skip rendering if category.name is "user"
                if (category?.name === 'user') return null;

              return (
                <li className="category-item my-color-bg-light-1 d-flex justify-content-center align-items-center" key={index} onClick={_handleClick(category)}>
                  <div className="category-name">{category.name}</div>
                </li>
              )})}
              {!filteredCategories.length && (<li className="category-item my-color-bg-light-1 d-flex justify-content-center align-items-center">
                <div className="category-name text-danger">No category found</div>
              </li>)}
            </ul>)}

          </div>
          <div className="modal-footer">
            <button type="btn button" className="btn btn-sm btn-secondary" data-dismiss="modal" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModel;
